import React, { FC, useRef, useState } from 'react';
import { AppBar, Box, Container, Fade, Grid, makeStyles, Theme, Toolbar, Typography } from '@material-ui/core';
import Link from 'next/link';
import { routes } from 'config/routes/routes';
import { MenuProps } from 'containers/layout/navbar/types';
import { LogoU1 } from 'svgs';
import { TLabelLinks } from 'containers/layout/types';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import AccentLink from 'components/atoms/AccentLink';

interface NavLinkProps {
  path: string;
  label: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
  },
  toolbar: {
    padding: theme.spacing(5, 0),
    transition: 'padding 300ms ease-out',
    maxHeight: 81,
  },
  link: {
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(6),
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  linkList: {
    margin: theme.spacing(4, 'auto', 2),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2, 4),
    minWidth: 270,
    border: `1px solid ${theme.palette.primary.contrastText}`,
    position: 'absolute',
    maxHeight: 128,
  },
  linkListServices: {
    margin: theme.spacing(6, -250, 2, -80),
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(5, 5, 2, ),
    maxWidth: 1130,
    position: 'absolute',
    maxHeight: 124,
    display: 'flex',
    border: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.up('xl')]: {
      maxWidth: 1350,
      margin: theme.spacing(4, -70, 2),
    },
  },
  label: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 500,
  },
  dropdownLink: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    transition: 'all 300ms',
    paddingLeft: theme.spacing(6),
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  text: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(6),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  container: {
    maxWidth: 1500,
  },
}));

const DesktopMenu: FC<MenuProps> = () => {
  const classes = useStyles();

  const NavLink: FC<NavLinkProps> = ({ label, path }) => {
    const ref = useRef(null);

    return (
      <div ref={ref}>
        <Link href={path} passHref>
          <a>
            <Typography className={classes.link}>{label}</Typography>
          </a>
        </Link>
      </div>
    );
  };

  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Container className={classes.container}>
          <Grid container>
            <Grid xl={3} lg={1} item>
              <Box display="flex" alignItems="center" height="100%">
                <Link href={'/'} passHref>
                  <a>
                    <LogoU1 />
                  </a>
                </Link>
              </Box>
            </Grid>
            <Grid item xl={9} lg={11}>
              <Box display="flex" justifyContent="flex-end" alignItems="center" mt={1}>
                {routes.map(({ label, links, route }, index) => (
                  <Box key={index}>
                    {links ? (
                      label === 'Consulting & Business Services' ? (
                        <NavLinkDropdownServices label={label} links={links} route={route} classes={classes} />
                      ) : (
                        <NavLinkDropdown label={label} links={links} route={route} classes={classes} />
                      )
                    ) : route === '/contact-us' ? (
                      <AccentLink href={route}>{label}</AccentLink>
                    ) : (
                      <NavLink label={label} path={route} />
                    )}
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

interface INavLinkDropdown {
  label: string;
  links: TLabelLinks[];
  route: string;
  classes: ClassNameMap<string>;
}

const NavLinkDropdown: FC<INavLinkDropdown> = ({ classes, route, label, links }) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const toggleShowMore = () => setShowMore(true);
  const toggleShowLess = () => setShowMore(false);

  return (
    <Box onMouseEnter={toggleShowMore} onMouseLeave={toggleShowLess} position="relative">
      <Link href={route} passHref>
        <Typography variant="body1" component="span" className={classes.text}>
          {label}
        </Typography>
      </Link>
      <Fade in={showMore}>
        <Box>
          {links.map(({ label, links }, index) => (
            <Box key={index} className={classes.linkList}>
              {links.map(({ label, route }) => (
                <Box mb={1} key={route + label}>
                  <Link href={route} passHref>
                    <a>
                      <Typography component="span" color={'textSecondary'} className={classes.label}>
                        {label}
                      </Typography>
                    </a>
                  </Link>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Fade>
    </Box>
  );
};

const NavLinkDropdownServices: FC<INavLinkDropdown> = ({ classes, route, label, links }) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const toggleShowMore = () => setShowMore(true);
  const toggleShowLess = () => setShowMore(false);

  return (
    <Box onMouseEnter={toggleShowMore} onMouseLeave={toggleShowLess} position="relative">
      <Link href={route} passHref>
        <Typography variant="body1" component="span" className={classes.text}>
          {label}
        </Typography>
      </Link>
      <Fade in={showMore}>
        <Box>
          {links.map(({ label, links }, index) => (
            <Box key={index} className={classes.linkListServices}>
              <Box display="flex" flexWrap="wrap" justifyContent="flex-start" alignItems="center">
                {links.map(({ label, route }) => (
                  <Box key={route + label} width="20%" mb={2}>
                    <Link href={route} passHref>
                      <a>
                        <Typography component="span" color="textSecondary" className={classes.label}>
                          {label}
                        </Typography>
                      </a>
                    </Link>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </Fade>
    </Box>
  );
};

export default DesktopMenu;
