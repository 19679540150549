import React, { FC, useState } from 'react';
import { Theme, Box, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import {
  FooterLogo,
  FacebookIcon,
  LinkedinIcon,
  LocationIcon,
  PhoneIcon,
  ArrowIcon,
  TermsOfUse,
  PrivacyPolicy,
} from 'svgs/footer';
import { routesFooter } from 'config/routes/routes';
import Link from 'next/link';
import { INSTAGRAM_LINK, LOCATION_ADDRESS } from 'config';
import { InstagramDarkIcon } from '../../../svgs';
import { ArrowDownWithOpacityIcon } from '../../../svgs/consulting-business-services';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: theme.spacing(6, 0),
    position: 'relative',
  },
  title: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    cursor: 'pointer',
  },
  titleService: {
    color: theme.palette.common.white,
    margin: theme.spacing(2, 0),
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
    opacity: 0.2,
  },
  divider: {
    margin: theme.spacing(6, 0),
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.common.white,
    opacity: 0.2,
  },
  infoText: {
    fontWeight: 500,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
  companyName: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  smBox: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  fb: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(0),
    },
  },
  arrowButton: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
    position: 'absolute',
    top: -20,
    right: 20,
    transition: 'background-color 0.3s ease',
    willChange: 'background-color',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.contrastText,
    },
  },
  pinIcon: {
    marginRight: theme.spacing(1),
    marginBottom: -4,
  },
  gridItem: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    padding: theme.spacing(4, 0, 1),
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  icon: {
    marginTop: -10,
  },
  links: {
    paddingLeft: 12,
  },
}));

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const MobileFooter: FC = () => {
  const currentYear = new Date().getFullYear();
  const classes = useStyles();
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const handleClick = (index: number) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.arrowButton} onClick={scrollToTop} display="flex" justifyContent="center" alignItems="center">
        <ArrowIcon />
      </Box>
      <Container>
        <Grid container>
          {routesFooter.map(({ route, label, links }, index) => {
            return (
              <Grid xs={12} lg={3} item key={index} className={classes.gridItem}>
                {label === 'Consulting & Business Services' ? (
                  links.map((item, index) => {
                    const isOpen = openIndexes.includes(index);
                    return (
                      <div key={index}>
                        <Box display="flex" onClick={() => handleClick(index)} justifyContent="space-between" alignItems="center">
                          <Typography variant="body1" className={classes.title}>
                            {item.label}
                          </Typography>
                          <ArrowDownWithOpacityIcon
                            className={classes.icon}
                            style={{
                              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                              transition: 'transform 0.3s ease',
                              opacity: isOpen ? '20%' : '100%',
                            }}
                          />
                        </Box>

                        {isOpen && (
                          <Box ml={2}>
                            {item.links.map((link) => (
                              <Link key={link.label} href={link.route} passHref>
                                <a>
                                  <Typography className={classes.titleService}>{link.label}</Typography>
                                </a>
                              </Link>
                            ))}
                          </Box>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <Link href={route} passHref>
                    <a>
                      <Typography variant="body1" className={classes.title}>
                        {label}
                      </Typography>
                    </a>
                  </Link>
                )}
              </Grid>
            );
          })}
        </Grid>
        <Box className={classes.divider} />
        <Box className={classes.smBox} mb={3} textAlign="center">
          <FooterLogo />
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" align="center" className={classes.infoText}>
            <LocationIcon className={classes.pinIcon} />
            {LOCATION_ADDRESS}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          <PhoneIcon />
          <a href="tel:+2013088253">
            <Typography variant="body1" className={classes.infoText}>
              (201) 308-8253
            </Typography>
          </a>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <Box display="flex" mr={5}>
            <TermsOfUse />
            <Link href="/terms-of-use">
              <a>
                <Typography variant="body1" className={classes.infoText}>
                  Terms of use
                </Typography>
              </a>
            </Link>
          </Box>
          <Box display="flex">
            <PrivacyPolicy />
            <Link href="/privacy-policy">
              <a>
                <Typography variant="body1" className={classes.infoText}>
                  Privacy policy
                </Typography>
              </a>
            </Link>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
          <Box display="flex" mr={2}>
            <Link href="https://www.linkedin.com/company/utilitiesone/?viewAsMember=true">
              <a>
                <Box display="flex" alignItems="center">
                  <LinkedinIcon />
                  <Typography variant="body1" className={classes.infoText}>
                    LinkedIn
                  </Typography>
                </Box>
              </a>
            </Link>
          </Box>
          <Box display="flex" mr={0.5}>
            <Link href="https://www.facebook.com/UtilitiesOne/?view_public_for=1625716964118130">
              <a>
                <Box display="flex" alignItems="center" className={classes.fb}>
                  <FacebookIcon />
                  <Typography variant="body1" className={classes.infoText}>
                    Facebook
                  </Typography>
                </Box>
              </a>
            </Link>
          </Box>
          <Box display="flex">
            <Link href={INSTAGRAM_LINK}>
              <a>
                <Box display="flex" alignItems="center">
                  <InstagramDarkIcon />
                  <Typography variant="body1" className={classes.infoText}>
                    Instagram
                  </Typography>
                </Box>
              </a>
            </Link>
          </Box>
        </Box>
        <Box textAlign="center" mt={10}>
          <Typography variant="body2" className={classes.title}>
            COPYRIGHT &#169; {currentYear} UTILITIES ONE
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default MobileFooter;
